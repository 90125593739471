import { ElLoading } from "element-plus";

// 全局请求 loading
let loadingInstance
// 开启 Loading
const startLoading = () => {
  loadingInstance = ElLoading.service({
    target: document.getElementById('app'),
    fullscreen: true,
    lock: true,
    text: "",
    spinner: '',
    background: "rgba(0, 0, 0, 0.1)"
  });
};

// 结束 Loading
const endLoading = () => {
  loadingInstance.close();
};

// 显示全屏加载
let needLoadingRequestCount = 0;
const showFullScreenLoading = () => {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
};

// 隐藏全屏加载
const tryHideFullScreenLoading = () => {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
};

// 导出钩子
export const useLoading = () => {
  return {
    start: showFullScreenLoading,
    end: tryHideFullScreenLoading,
  }
}
