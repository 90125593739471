import { useCache } from '@/hooks/useCache'
const { wsCache } = useCache()

export default (app) => {
  app.directive('debounce', {
    mounted(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.disabled = true;
          setTimeout(() => {
            el.disabled = false;
          }, binding.value || 1000)
        }
      })
    }
  })
  // 权限级别按钮
  app.directive('disabled', {
    mounted(el, binding) {
      const Auth = wsCache.get('Auth')
      if(!Auth.includes(binding.value.name)){
        el.disabled = true
        el.classList.add('is-disabled')
      }
    }
  })
}
