/**
 * 转化为 FormData
 */
export const convertToFormData = (data) => {
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

// 测试本地查找
export const searchData = (keyword, type, Arr) => {
  const returnArr = []
  if(type == 'verification_type' || type == 'total_amount' || type == 'unverifiedAmount'){
      Arr.map(item => {
          if(item[type] == keyword){
          returnArr.push(item)
          }
      })
  }else{
      Arr.map(item => {
          let reg = new RegExp(keyword)
          if(reg.test(item[type])){
          returnArr.push(item)
          }
      })
  }
  return returnArr
}

// 递归+闭包遍历处理拿到所有的权限名字
let returnArr = []
export const getAuthMore = (data) => {
  let EntArr = Object.entries(data)
  if(EntArr.length == 0) return
  EntArr.forEach(item => {
    returnArr.push(item[0])
    if(Array.isArray(item[1])){
        item[1].forEach(item => returnArr.push(item))
        return
    }else{
        getAuthMore(item[1])
    }
  })
  return returnArr
}
// 用完闭包进行回收处理
export const initReturnArr = () => {
  returnArr = []
}

// 获取路由侧边栏,不能复用是事件循环机制导致，避免增加复杂性再多写一个函数
let returnAuth = []
export const getAuthSide = (data) => {
  let EntArr = Object.entries(data)
  if(EntArr.length == 0) return
  EntArr.forEach(item => {
    returnAuth.push(item[0])
    if(Array.isArray(item[1])){
        return
    }else{
      getAuthSide(item[1])
    }
  })
  return returnAuth
}
export const initReturnAuth = () => {
  returnAuth = []
}
