<template>
  <el-container class="layout-container-demo" height="100vh">
    <!-- <el-header><Header /></el-header> -->
    <el-container>
      <Sidebar />
      <el-main class="mainView">
        <!-- <tab /> -->
        <Tabs />
        <router-view v-slot="{ Component, route }">
          <transition name="fade-right" mode="out-in">
            <keep-alive :exclude="reloadMenu">
              <component :is="Component" :key="route.path"/>
            </keep-alive>
          </transition>
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import Sidebar from './components/Sidebar.vue'
// import Header from './components/Header.vue'
import Tabs from './components/Tabs.vue'
import { useMenu } from '@/store/menu'
import { storeToRefs } from 'pinia'
const menu = useMenu()
const { reloadMenu } = storeToRefs(menu)
</script>

<style scoped>
.layout-container-demo .mainView {
    padding: 5px !important;
    overflow-x: hidden;
  }
.layout-container-demo .el-header {
  position: relative;
  background-color: rgba(42, 130, 228, 1);
  color: var(--el-text-color-primary);
  color: #fff;
  font-size: 15px;
}
.layout-container-demo .el-aside {
  color: var(--el-text-color-primary);
  background: var(--el-color-primary-light-8);
}
.layout-container-demo .el-menu {
  border-right: none;
}
.layout-container-demo .el-main {
  padding: 0;
}

/* fade */
.fade-right-enter-to,
.fade-right-leave-from {
  opacity: 1;
  transform: none;
}

.fade-right-enter-active,
.fade-right-leave-active {
  transition: all 0.5s;
}

.fade-right-enter-from,
.fade-right-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
