import { service } from '@/utils/request'
import { useCache } from '@/hooks/useCache'

const { wsCache } = useCache()
const request = (option) => {
  const { url, method, params, data, headersType, responseType } = option
  return service({
    url: url,
    method,
    params,
    data,
    responseType: responseType,
    headers: {
      'Content-Type': headersType || 'application/json',
      'token': wsCache.get('Token') || '',//一开始就要token
    },
  })
}

function getFn(option) {
  return request({ method: 'get', ...option })
}

function postFn(option) {
  return request({ method: 'post', ...option })
}

export const useAxios = () => {
  return {
    get: getFn,
    post: postFn,
  }
}
