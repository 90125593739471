<script setup>
import { ref,provide,nextTick} from 'vue'
const isRouterAlive = ref(true)
const reload = () => {
  isRouterAlive.value = false
  nextTick(() => {
    isRouterAlive.value = true
  })
}

provide('reload', reload)
</script>

<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<style scoped>
#app{
  min-height: 100vh;
  background-color: #fff;
}
</style>